<template>
    <!-- 会员中心 -->
    <div class="finished">
      <div class="project">
          <h3 class="title">保证担保</h3>
        <div class="top-con">
          <div class="box" :style="{'opacity': isShow ? 1 : 0.5}" @click="publishNeedsInfo">
            <p>
              <img src="@/assets/imgs/zrdb2.png" alt="" />
              <img
                class="isShow"
                v-if="isShow"
                src="@/assets/imgs/chooseDown.png"
                alt=""
              />
              <span>找人担保</span>
            </p>
          </div>
          <div class="box" :style="{'opacity': isShow ? 0.5 : 1}" @click="publishSendsInfo">
            <p>
              <img src="@/assets/imgs/wrdb2.png" alt="" />
              <img
                class="isShow"
                v-if="!isShow"
                src="@/assets/imgs/chooseDown.png"
                alt=""
              />
              <span>为人担保</span>
            </p>
          </div>
        </div>
        <div>
          <div class="steps" v-show="isShow">
            <div v-show="isSteps">
              <my-steps
                ref="mySteps"
                :type1="type1"
                :steps="steps"
                @getCurrentStep="getCurrentStep"
              >
                <div class="steps-mid">
                  <steps-form :active="active" :type="type"></steps-form>
                </div>
              </my-steps>
            </div>
            <div v-show="!isSteps">
              <zrdbList></zrdbList>
            </div>
          </div>
          <div class="steps" v-show="!isShow">
            <div v-show="isStepss">
              <my-steps
                ref="myStepss"
                :steps="stepss"
                :type1="type1"
                @getCurrentStep="getCurrentStep"
              >
                <div class="steps-mid">
                  <steps-forms :active="actives" :type="type"></steps-forms>
                </div>
              </my-steps>
            </div>
            <div v-show="!isStepss">
              <wrdbList></wrdbList>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import StepsForms from "../components/stepsFormEnsure";
  import StepsForm from "../components/stepsForm";
  import zrdbList from "../components/zrdbList";
  import wrdbList from "../components/wrdbList";
  import mySteps from "@/components/mySteps/index";
  export default {
    components: {
      StepsForm,
      StepsForms,
      mySteps,
      zrdbList,
      wrdbList
    },
    data() {
      return {
        // isSteps: false,
        // isStepss: false,
        isSteps: true,
        isStepss: true,
        type: 3,
        type1: 1,
        isShow: true,
        active: 0,
        actives: 0,
        steps: [
          "填写贷款信息",
          "填写个人信息",
          "填写产权信息",
          "填写联系人信息",
          "上传相关材料",
          "提交成功"
        ],
        stepss: ["填写个人信息", "填写个人产权信息", "上传相关材料", "提交成功"],
      };
    },
    methods: {
      //获取当前进行到第几步
      getCurrentStep(val) {
        if (this.isShow) {
          this.active = val;
          this.type1 = 1;
        } else {
          this.actives = val;
          this.type1 = 2;
        }
      },
      next() {
        if (this.isShow) {
          this.$refs.mySteps.next();
        } else {
          this.$refs.myStepss.next();
        }
      },
      //找人担保
      publishNeedsInfo() {
        this.isShow = true;
        this.isSteps = true;
        this.active=0
        this.$refs.mySteps.active = 0;
      },
      //为人担保
      publishSendsInfo() {
        this.isShow = false;
        this.isStepss = true;
        this.actives=0
        this.$refs.myStepss.active = 0;
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
  /deep/ .el-radio__input {
    display: none;
  }
  /deep/ .el-radio {
    margin-right: 0;
  }
  /deep/ .el-button--primary {
    width: 256px;
  }
  .finished {
    text-align: left;
    height: 100%;
    position: relative;
    // margin-top: 46px;
  
    h3{
    text-indent: 30px;
  }
    .project {
      // margin-top: 58px;
      .table-con {
        margin-top: 12px;
        padding: 0 15px 0 18px;
      }
    }
    .steps {
      margin-top: 42px;
      &-mid {
        margin-top: 58px;
      }
    }
    .top-con {
      padding-left: 35px;
      .desc {
        font-size: 20px;
        text-align: center;
        margin-top: 53px;
        margin-bottom: 40px;
      }
      .box {
        width: 412px;
        height: 154px;
        line-height: 154px;
        display: inline-block;
        background-image: linear-gradient(
            -70deg,
            #2982fe 0%,
            #04d4e7 100%,
            #7cb1bc 100%,
            #f38d90 100%
          ),
          linear-gradient(90deg, #fe9c5f 0%, #f8323f 100%);
        background-blend-mode: normal, normal;
        border-radius: 15px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        p {
          margin: 0;
          font-size: 24px;
          img {
            vertical-align: middle;
            margin-right: 12px;
          }
        }
      }
      .box:last-child {
        margin-left: 21px;
      }
    }
    .isShow {
      position: absolute;
      // top: 216px;
      top: 154px;
    }
  }
  </style>
  
  <style lang="less"></style>
  