<template>
  <div style="position: absolute; left: 24px">
    <div class="textFive">
      <p>您已成功提交申请，我们将尽快对您的资料进行验证审核。</p>
      <p>
        验证结果将第一时间通过“站内消息”向您发送。
      </p>
      <p>点击“确认”后，可在线查看并为意向贷款人发起交流和担保。</p>
    </div>
    <el-button class="btn btn4" type="primary" @click="next">确认</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    next() {
      this.$parent.next();
    }
  }
};
</script>

<style lang="less" scoped>
.btn4 {
  position: absolute;
  left: 300px;
  top: 170px;
}
.textFive {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #333333;
  width: 500px;
  text-align: center;
  position: absolute;
  left: 170px;
  top: 40px;
}
</style>
