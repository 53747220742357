<template>
  <div>
    <div v-if="active === 0">
      <steps-one :type="type"></steps-one>
    </div>
    <div v-if="active === 1">
      <steps-two :type="type"></steps-two>
    </div>
    <div v-if="active === 2">
      <steps-four :type="type"></steps-four>
    </div>
    <div v-if="active === 3">
      <steps-five :type="type"></steps-five>
    </div>
  </div>
</template>

<script>
import StepsTwo from "./stepsTwo";
import StepsOne from "./stepsOne";
import StepsFour from "./stepsFourE";
import StepsFive from "./stepsFiveEwrdb";
export default {
  components: {
    StepsFive,
    StepsFour,
    StepsTwo,
    StepsOne,
  },
  props: ["active","type"],
  data() {
    return {
      query:{
        userId:"46b9c1e2ee7c01aefd6b6f3a7f0b6500",
        uploadImgId:"",
        loansPurpose:"1",
        loansLimit:"10000",
        loansPeriod:"10",
        propertyType:"",
        propertyArea:"",
        propertyAddress:"",
        surroundingInfo:"",
        guaranteeName:"",
        guaranteeContact:"",
        guaranteeAddress:"",
        guaranteeUnit:"",
        guaranteeUnitCode:"",
        guaranteeUnitAddress:"",
        areaCode:"10025",
      }
    };
  },
  methods: {
    next() {
      this.$parent.next();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
