<template>
  <div class="content" style="position: absolute; left: 24px">
    <div>
      <p class="upLoadId">上传身份证</p>
      <span class="upLoadId-id1 Upd">
        <el-upload
          class="avatar-uploader"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :before-upload="beforeAvatarUpload"
          :on-change="httpRequest"
          action=""
          :auto-upload="false"
          :on-remove="deleteFile"
          :class="{ 'demo-httpRequestImg': httpRequestImg }"
        >
          <img src="@/assets/imgs/Id2.png" alt="" />
        </el-upload>
        <el-dialog
          :visible.sync="dialogVisibleImg"
          append-to-body
          class="ImgClass"
        >
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </span>
      <span class="upLoadId-id2 Upd">
        <el-upload
          class="avatar-uploader"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :before-upload="beforeAvatarUpload"
          :on-change="httpRequest1"
          action=""
          :auto-upload="false"
          :on-remove="deleteFile"
          :class="{ 'demo-httpRequestImg1': httpRequestImg1 }"
        >
          <img src="@/assets/imgs/Id1.png" alt="" />
        </el-upload>
      </span>
      <p class="info">
        请确保上传图片<span
          >边框完整，字体清晰，亮度均匀，图片最大不超过6M</span
        >
      </p>
    </div>
    <div>
      <p class="upLoadId cqpz">产权凭证</p>
      <span class="cqpz-upL">
        <el-upload
          class="avatar-uploader"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :before-upload="beforeAvatarUpload"
          :on-change="httpRequest2"
          action=""
          :auto-upload="false"
          :on-remove="deleteFile"
          :class="{ 'demo-httpRequestImg2': httpRequestImg2 }"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <p class="text">请选择图片</p>
        <p class="textInfo">图片最大不超过6M</p>
      </span>
    </div>
    <div>
      <p class="upLoadId tyzm">银行流水凭证</p>
      <span class="tyzm-upL">
        <el-upload
          class="avatar-uploader"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :before-upload="beforeAvatarUpload"
          :on-change="httpRequest3"
          action=""
          :auto-upload="false"
          :on-remove="deleteFile"
          :class="{ 'demo-httpRequestImg3': httpRequestImg3 }"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <p class="text">请选择图片</p>
      </span>
    </div>
    <el-button class="btn3" type="primary" @click="next">下一步</el-button>
  </div>
</template>

<script>
import { uploadFile, deleteFile } from "@/api/fileApi";
import { uploadPhoto5 } from "@/api/add";
import { getUpLoadList, getApplyList } from "@/api/ApplicationsFor";
import { mapGetters } from "vuex";
export default {
  props: ["type"],
  computed: mapGetters(["userInfo"]),
  data() {
    return {
      dialogImageUrl: "", //预览url
      dialogVisibleImg: false,
      httpRequestImg: false, //展示单个图片
      httpRequestImg1: false, //展示单个图片
      httpRequestImg2: false, //展示单个图片
      httpRequestImg3: false, //展示单个图片
      uploadUrl: "",
      fileArray: [],
      photoId: "",
      imageInfo:{},
    };
  },
  methods: {
    async uploadFile(file, type) {
      let param = new FormData();
      param.append("catalog", "vip");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      if (res.code == 0) {
        file.url2 = res.data.url;
        this.imageInfo[type] = res.data.url;
      }
    },
    httpRequest(file) {
      this.uploadFile(file, "idCardFrontImg");
      this.httpRequestImg = true;
    },
    httpRequest1(file) {
      this.httpRequestImg1 = true;
      this.uploadFile(file, "idCardBackImg");
    },
    httpRequest2(file) {
      this.httpRequestImg2 = true;
      this.uploadFile(file, "propertyCertificateImg");
    },
    httpRequest3(file) {
      this.httpRequestImg3 = true;
      this.uploadFile(file, "bankAccountImg");
    },
    async deleteFile(item) {
      let res = await deleteFile({ ossFilePath: item.url2 });
      if (res.code == 0 && res.data == true) {
        console.log("删除成功");
      }
    },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      const isLt50M = file.size / 1024 / 1024 < 6;
      if (!extension) {
        this.$message({
          message: "上传图片只能是jpg / png / bpm格式!",
          type: "error",
        });
        return false; //必须加上return false; 才能阻止
      }
      // console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 6MB!",
          type: "error",
        });
        return false;
      }
      return extension || isLt50M;
    },
    handlePictureCardPreview(file) {
      //预览
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    async getTableData() {
      let params = {
        ...this.imageInfo,
        userId: this.userInfo.id,
      };
      let res = await uploadPhoto5(params);
      if (res.status == 200) {
        this.photoId = res.data;
        this.$parent.query.uploadImgId = this.photoId;
        this.getAddData();
      }
    },

    async getAddData() {
      let params = this.$parent.query;
      let res = await getApplyList(params);
      if (res.status === 200) {
        this.$parent.next();
        if (res.data) {
          localStorage.setItem("guaranteeApplyId", res.data.guaranteeApplyId);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    next() {
      if (Object.keys(this.imageInfo).length === 4) {
        this.getTableData();
      } else {
        this.$message({
          message: "警告：请上传 4 张图片",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.demo-httpRequestImg {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
.demo-httpRequestImg1 {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
.demo-httpRequestImg2 {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
.demo-httpRequestImg3 {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
.content /deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 174px;
  height: 144px;
  border-radius: 10px;
  line-height: 143px;
  text-align: center;
}
.content /deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.upLoadId {
  width: 100px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  color: #333333;
  font-weight: 700;
  &-id1 {
    position: absolute;
    top: 0;
    left: 150px;
  }
  &-id2 {
    position: absolute;
    top: 0;
    left: 410px;
  }
}
.info {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  width: 450px;
  position: absolute;
  top: 160px;
  left: 150px;
  color: #333;
  span {
    color: #00a0e9;
  }
}
.cqpz {
  width: 80px;
  position: absolute;
  top: 250px;
  left: 20px;
  &-upL {
    position: absolute;
    top: 250px;
    left: 150px;
  }
}
.tyzm {
  width: 120px;
  position: absolute;
  top: 250px;
  left: 370px;
  &-upL {
    position: absolute;
    top: 250px;
    left: 520px;
  }
}
.text {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #333333;
  width: 148px;
  text-align: center;
}
.textInfo {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #00a0e9;
  width: 148px;
  text-align: center;
}
.btn3 {
  position: absolute;
  left: 300px;
  top: 475px;
}
</style>
