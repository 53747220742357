<template>
  <div class="content">
    <div class="content-top">
      <span class="content-top-title">选择担保人</span>
      <span class="content-top-input">
        <el-input placeholder="请输入贷款人编号" v-model="input">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </span>
    </div>
    <div class="content-mid">
      <div v-for="(item, index) in dataList" :key="index">
        <el-card class="box-card content-mid-all">
          <div>
            <img src="@/assets/imgs/person.png" alt="" />
          </div>
          <div class="content-mid-all-title">
            <p>{{ item.guaranteeName }}</p>
            <div class="content-mid-all-title-btn">
              在线交流
            </div>
          </div>
          <div class="content-mid-all-info">
            <div>
              抵押产权种类：<span>{{ item.propertyTypeName }}</span>
              <p>贷款人编号：{{ item.financierCode }}</p>
            </div>
            <div class="content-mid-all-info-je">
              <span>{{ item.loansLimit }}元</span>
              <p>申请贷款金额</p>
            </div>
            <div class="content-mid-all-info-tel">
              <span>{{ item.guaranteeUnitCode }}</span>
              <p>联系方式</p>
            </div>
          </div>
          <div
            class="content-mid-all-btn"
            @click="handleClick(item)"
            v-if="item.isShow"
          >
            <div class="content-mid-all-btn-btn1">邀请他</div>
          </div>
          <div class="content-mid-all-btn" @click="handleClick1(item)" v-else>
            <div class="content-mid-all-btn-btn2">取消邀请</div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="content-bottom ">
      <div class="pagenation">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="total, slot, prev, pager, next, slot"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getZrdbList,
  getGuaranteeInviteList
} from "@/api/ApplicationsFor";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      input: "",
      currentPage: 1,
      pageSize: 3,
      total: 0,
      dataList: [],
      query: {
        userId: "decad426f21ce9c6aacc9c172b636f0d",
        loansApplyId: localStorage.getItem("loansApplyId"),
        guaranteeApplyId: ""
      }
    };
  },
  computed: mapGetters(["userInfo"]),
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      let params = {
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        userId: this.userInfo.id
      };
      let res = await getZrdbList(params);
      this.dataList = res.data.records;
      for (var i in this.dataList) {
        this.$set(this.dataList[i], "isShow", true);
      }
      this.total = res.data.total;
    },
    handleClick(val) {
      console.log(val);
      val.isShow = false;
      this.query.guaranteeApplyId = val.val;
      this.getZtdbBtn();
    },
    handleClick1(val) {
      val.isShow = true;
    },
    async getZtdbBtn() {
      let params = this.query;
      let res = await getGuaranteeInviteList(params);
      if (res.status === 200) {
        this.$message({
          message: "邀请他担保成功",
          type: "success"
        });
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  width: 830px;
  margin-top: 70px;
  margin-left: 40px;
  &-top {
    &-title {
      font-size: 20px;
      color: #333333;
      font-weight: 600;
    }
    &-input {
      float: right;
    }
  }
  &-mid {
    margin-top: 40px;
    &-all {
      position: relative;
      margin-top: 20px;
      &-title {
        position: absolute;
        top: 10px;
        left: 135px;
        p {
          font-size: 20px;
          color: #333;
          font-weight: 600;
        }
        &-btn {
          position: absolute;
          top: 21px;
          left: 85px;
          width: 88px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          cursor: pointer;
          background-color: #00a0e9;
          border-radius: 5px;
          font-size: 14px;
          color: #fff;
        }
      }
      &-info {
        position: absolute;
        top: 78px;
        left: 135px;
        font-size: 14px;
        p {
          color: #666;
        }
        div {
          span {
            font-weight: 600;
          }
        }
        &-je {
          width: 122px;
          position: absolute;
          top: -4px;
          left: 235px;
          span {
            font-size: 16px;
            font-weight: 600;
            color: #e22a2a;
          }
        }
        &-tel {
          width: 122px;
          position: absolute;
          top: 0px;
          left: 400px;
          span {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      &-btn {
        position: absolute;
        right: 30px;
        top: 67px;
        width: 95px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        &-btn1 {
          background-color: #00a0e9;
          color: #fff;
        }
        &-btn2 {
          background-color: rgba(228, 228, 228, 1);
          color: #000;
        }
      }
    }
  }
  &-bottom {
    .pagenation {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>
